import React from "react"
import Layout from "../../templates/layout"
import SEO from "../../components/seo/seo"
import Hero from "../../components/hero/hero"
import HaveQuestions from "../../components/haveQuestions/haveQuestions"
import Button from "../../components/button/button"
import SarclisaLogo from "../../images/logos/sarclisa_patient.svg";
import EnrollIcon from "../../images/icons/icon-enroll-sarclisa-hero.svg"
import QuestionsIcon from "../../images/icons/icon-questions-sarclisa.svg"
import BackToTop from "../../components/backToTop/BackToTop"
import PrescribingInfo from "../../components/prescribingInfo/prescribingInfo"
import style from './enrollment.module.scss';
import rxIcon from '../../images/icons/icon-rx-sarclisa.svg'
import speak from '../../images/icons/icon-SARCLISA_Speak_with_doctor.png'
import talk from '../../images/icons/icon-SARCLISA_Care_Manager.png'
import LinkCard from "../../components/linkCard/linkCard"
import AssistCallout from "../../components/assistCallout/AssistCallout"

const pageContent = () => (
  <Layout pageid="page-enrollment" pageClass="patient-sarclisa-enrollment mobile-height-hero">
    <SEO
      title="Patient enrollment with CareASSIST for SARCLISA® (isatuximab-irfc) | Patient Site"
      keywords="CareASSIST, Sanofi, SARCLISA® (isatuximab-irfc), Patient Site, Enrollment"
      description="Ready to enroll? Learn how to enroll with your doctor here. See Prescribing Info, including Patient Info"
    />

    <Hero
      headline="Enrollment"
      copy="Our Care Managers can help you throughout this process. Once your enrollment is complete, they will reach out with next steps"
      brandLogo={SarclisaLogo}
      brandAlt="Sarclisa Logo"
      heroImgClass="hero-icon"
      hasLogo={true}
      hasIcon={true}
      heroIcon={EnrollIcon}
      isExternalButton=""
      iconAlt="Enrollment Icon"
      btnLink="../../pdfs/careassist-program-enrollment-form.pdf"
      btnText=""
      btnClass=""
    />

<div className={style.containerColor + " color-block"}>
      <h2 className="text-center mb-2">There are two ways you can get started with enrollment</h2>
      <div className={style.flexContainer}>
        {/* <div className={style.flexItem}>
          <img src={monitor} alt="monitor icon" />
          <h4> Enroll online</h4>
          <p>Your doctor's office initiates and complete most of this process. You will receive a link via text or email to provide your authorization.</p>
        </div> */}
        <LinkCard
          img={speak}
          alt="Doctor contact icon"          
          cardTitle=""
          cardText="Talk to your doctor"
          cardLink=""
          cardClass="patient enrollment"
          linkID="financial-assistance-link"
          linkImgID="financial-assistance-link-image"
          linkTitleID="financial-assistance-link-title"
          linkTxtID="financial-assistance-link-text"
        />
        <p className={style.or + " bold mr-16"}>OR</p>
        {/* <div className={style.flexItem}>
          <img src={fax} alt="fax icon" />
          <h4> Fax or mail</h4>
          <p>Fill out the form with your doctor's office and send it to us by fax.</p>
          <div className={style.enrollButton}>
            <Button className="red" text="Enrollment form" url="#" />
          </div>
        </div> */}
        <LinkCard
          img={talk}
          alt="Care Manager contact icon"          
          cardTitle=""
          cardText="Speak with a Care Manager "
          cardLink=""
          cardClass="blue static patient enrollment"
          linkID="financial-assistance-link"
          linkImgID="financial-assistance-link-image"
          linkTitleID="financial-assistance-link-title"
          linkTxtID="financial-assistance-link-text"
          btnTitle=""
          btnLink=""

        />
      </div>
    </div>
    <section className="content-section">
      <main>
        <div className={`main-content ${style.assistContainer}`}>
          <AssistCallout linkId="patient-sarclisa-copy-program" imgAlt="Copay card icon" productClass="sarclisa patient" img={rxIcon} content="Apply directly to our Copay Program" hasArrow={true} hasGoBtn={true} link="https://portal.trialcard.com/sanofi/careassist/" additionalClass="bold"/>
        </div>

      </main>
      <p className="pi-paragraph">Please see full <a id="patient-sarclisa-pi" href="https://products.sanofi.us/Sarclisa/sarclisa.pdf" target="_blank" rel="noreferrer">Prescribing Information</a>, including <a id="patient-sarclisa-patient-info" href="https://products.sanofi.us/Sarclisa/sarclisa_patient_information.pdf" target="_blank">Patient Information</a>.</p>
      <HaveQuestions icon={QuestionsIcon} /> 
    </section>  

    <div className="back-to-top-container"> 
      <BackToTop />
    </div>
  </Layout>
);

export default pageContent